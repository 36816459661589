import React from 'react';
import Blog from '../components/Blog/Blog';
import {
    Container
} from "reactstrap";
import { Fade } from 'react-reveal';

const BlogPosts = () => {
    return ( 
        <section className="section section-lg">
            <Container>
              <Fade bottom duration={1000} distance="40px">
              <div className="d-flex p-4">
                    <div>
                        <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-info">
                        <i className="ni ni-world-2 text-info" />
                        </div>
                    </div>
                    <div className="pl-4">
                        <h4 className="display-3 text-info">Recent Blog Posts</h4>
                    </div>
                </div>
                <Blog />
              </Fade>
            </Container>
          </section>
     );
}
 
export default BlogPosts;