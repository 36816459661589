import React, { Component } from "react";
import ShowBlog from "../ShowBlog/ShowBlog";
import Spinner from "../Spinner/Spinner";


export class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        ptitle: "",
        name: "",
        avtar: "",
        profileurl: "",
      },
      item: [],
      isloading: true,
      error: null
    };
  }

  mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.balasundar.com%2Frss.xml";

  componentDidMount() {
    this.fetchPosts();
  }

  fetchPosts = async () => {
    const query = `{
      user(username: "balasundar") {
        profilePicture
      }
      publication(host: "blog.balasundar.com") {
        title
        posts(first: 10){edges {
            node {
              title
              brief
              url
              coverImage{url}
              publishedAt
              author{profilePicture}
            }
          }
        }
      }
    }`
      const response = await fetch('https://gql.hashnode.com/', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ query }),
      })
      const ApiResponse = await response.json();
      this.setState( (pre) => ({ 
        profile: {
                  ...pre.profile,
                  ptitle: "Balasundar's Technical Blog",
                  profileurl: "https://blog.balasundar.com",
                  avatar: ApiResponse.data.user.profilePicture,
        },
        posts: ApiResponse.data.publication.posts.edges,
        isloading: false
      }));
    };

  render() {
   
    let post

    if (this.state.posts) {
      post = this.state.posts.map((post, index) => (
        <ShowBlog key={index} {...post.node} {...this.state.profile} {...index} />
      ))
    }
    if (this.state.isloading) {
      post = <Spinner />
    }
    if (this.state.error) {
      let error = this.state.error.code ? this.state.error.code : this.state.error.name;
      let errorMsg = this.state.error.message;
      post = (
        <>
          <h2 className="red center1">{error}</h2>
          <p className="errorMessage center1">{errorMsg}</p>
        </>
      );
    }
    return (
      <div className="container">
        <div className="row">
          {/* {this.state.item.map((post, index) => (
          <ShowBlog key={index} {...post} {...this.state.profile} {...index} />
        ))} */}

          {post}
        </div>

      </div>
    );
  }
}

export default Blog;
