import emoji from 'react-easy-emoji';

import mepcologo from './assets/img/icons/common/mepco-logo.png'
import ventunologo from './assets/img/icons/common/ventuno-logo.png'
import adorilogo from './assets/img/icons/common/adori-logo.png'
import poplogo from './assets/img/icons/common/pop-logo.png'

export const greetings = {
    "name": "Balasundar",
    "title": "Hi there!, I'm Balasundar",
    "description": "A passionate Python Developer having an experience of building Web applications and Backend services with Python / Django / Flask / FastAPI, AWS, Google Cloud, Docker, Kubernets and some other cool libraries and frameworks.",
    "resumeLink": "https://drive.google.com/file/d/19bzh1DdKdOwSPWhL3pvsx77e3QDM90C6/view?usp=sharing"
}

export const openSource = {
  githubUserName: 'sbalasundar',
};

export const contact = {
  
}

export const socialLinks = {
    "facebook": "https://www.facebook.com/bala.sundar.359",
    "instagram": "https://www.instagram.com/s_balasundar",
    "twitter": "https://twitter.com/Balasundar2796",
    "github": "https://github.com/SBalaSundar",
    "linkedin": "https://www.linkedin.com/in/balasundars/",
    "blog": "https://blog.balasundar.com"
}

export const skillsSection = {
    title: 'What I do',
    subTitle: 'CRAZY BACKEND DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK',
    skills: [
      emoji(
        '⚡ Develop and deploy high performance backed services'),
      emoji(
        '⚡ Integration of third party services such as Stripe/ Firebase/ AWS / Google Cloud'
      ),
    ],
    
    softwareSkills: [
      {
        skillName: 'html-5',
        fontAwesomeClassname: 'vscode-icons:file-type-html',
      },
      {
        skillName: 'css3',
        fontAwesomeClassname: 'vscode-icons:file-type-css',
      },
      {
        skillName: 'sass',
        fontAwesomeClassname: 'logos:sass',
      },
      {
        skillName: 'JavaScript',
        fontAwesomeClassname: 'logos:javascript',
      },
      {
        skillName: 'reactjs',
        fontAwesomeClassname: 'vscode-icons:file-type-reactjs',
      },
      {
        skillName: 'nodejs',
        fontAwesomeClassname: 'logos:nodejs-icon',
      },
      {
        skillName: 'npm',
        fontAwesomeClassname: 'vscode-icons:file-type-npm',
      },
      {
        skillName: 'sql-database',
        fontAwesomeClassname: 'vscode-icons:file-type-sql',
      },
      {
        skillName: 'aws',
        fontAwesomeClassname: 'logos:aws',
      },
      {
        skillName: 'google-cloud',
        fontAwesomeClassname: 'logos:google-cloud',
      },
      {
        skillName: 'firebase',
        fontAwesomeClassname: 'logos:firebase',
      },
      {
        skillName: 'python',
        fontAwesomeClassname: 'logos:python',
      },
      {
        skillName: 'git',
        fontAwesomeClassname: 'logos:git-icon',
      },
      {
        skillName: 'docker',
        fontAwesomeClassname: 'logos:docker-icon',
      },
    ],
}


export const SkillBars = [
    {
      Stack: 'Backend',
      progressPercentage: '80',
    },
    {
      Stack: 'Python',
      progressPercentage: '90',
    },
    {
      Stack: 'Django',
      progressPercentage: '90',
    },
    {
      Stack: 'FastAPI',
      progressPercentage: '90',
    },
    {
      Stack: 'Relational databases',
      progressPercentage: '85',
    },
  ]

export const educationInfo = [
    {
      schoolName: 'Mepco Schelnk Engineering College',
      subHeader: 'B.E Computer Science and Engineering',
      duration: 'June 2016 - May 2019',
      desc: 'Studied Bachelor of Computer Science and Engineering. Organized four events in two technical symposiums from 2016 to 2018, attended by more than a hundred participants each. ',
    },
    {
      schoolName: 'Arasan Ganesan Polytechnic College',
      subHeader: 'Diploma in Computer Engineering',
      duration: 'June 2011 - March 2014',
      desc: 'Studied Operating Systems, Computer Architecture, Computer Network Secirity, Cloud Computing, C Programming...etc',
    },
]

export const experience = [
  {
    role: 'Technician',
    company: 'Mepco Schlenk Engineering College, Sivakasi',
    companylogo: mepcologo,
    date: 'June 2014 – March 2016',
    desc:
      'Responsibilities:',
    descBullets: [
      'Manage the Computer Laboratory.',
      'Perform or ensure proper installation of operating systems(Linux and Microsoft Windows), or appropriate software.',
      'Install and perform minor repairs to hardware, software, or peripheral equipment.',
      'Monitor and troubleshooting computer network inside the department.',
    ],
  },
  {
    role: 'Associate Software Engineer',
    company: 'Ventuno Technologies, Chennai, India',
    companylogo: ventunologo,
    date: 'January 2020 – December 2020',
    desc:
      'Develop REST APIs(Django Rest Framework, Django ORM), Unit tests, API Documentation, Test Automation scripts for OTT Platform. Also developing Webpages(HTML, CSS and JavaScript) and databases (MySQL) and integrating the REST APIs.',
    descBullets: [
      'Develop REST APIs for the OTT Platform.',
      'Integration of REST APIs.',
      'Documenting the APIs.',
      'Design and develop Databases.'
      ],
  },
  {
    role: 'Software Engineer',
    company: 'Adorilabs, Inc, Bengaluru, India',
    companylogo: adorilogo,
    date: 'January 2021 – Jan 2024',
    desc:
      'Develop high performance backend services for Adori Studio and Insights and Design and development of Databases. Skills and Technologies: Python, FastAPI, REST API, Google Cloud Platform, Docker, Kubernets, MySQL, SQLAlchemy, Redis, HTML, CSS, JavaScript.',
    descBullets: [
      'Develop REST APIs.',
      'Mange MySQL databases.',
      'Document the APIs.',
      'API Testing',
      'Deploying backend services.'
      ],
  },
  {
    role: 'SDE II',
    company: 'POP',
    companylogo: poplogo,
    date: 'January 2024 – Present',
    descBullets: [
      "Led the design, development, and maintenance of critical backend services for POP's ecommerce platform, ensuring scalability, performance, and reliability",
      'Collaborated with cross-functional teams to gather and analyze requirements, translating business needs into technical solutions.',
      'Leveraged Python, Django, and FastAPI to build robust RESTful APIs, optimizing data flow and improving response times.',
      'Employed PostgreSQL for efficient data storage and retrieval, ensuring data integrity and supporting high-transaction volumes.',
      'Actively participated in Agile development practices, attending sprint planning, stand-up meetings, and retrospectives, contributing to a collaborative and iterative development environment.'
      ],
  },
]

export const projects = [
  {
    name: "WebApp for Agricultural Development",
    desc: "A web applications to help farmers, improve the productivity and avoid loss",
    link: {
      name: "Visit Repository",
      url: "https://github.com/SBalaSundar/Agriculture_Development_Web_App_using-ASP.Net"
    }
  },
  {
    name: "Online Computer Service Management System",
    desc: "Web application designed to access the computer services at the doorstep.",
    link: {
      name: "Visit Repository",
      url: "https://github.com/SBalaSundar/Online_computer_service_mngt_system-using-php"
    }
  },
  {
    name: "Tuberculosis Detection using Chest X-Ray",
    desc: "To Detection of Tuberculosis from chest X-Ray images at an earlier stageas soon as possible with higher accuracy by extracting features from the X-Ray images and Constructiong CNN.",
    link: {
      name: "Visit Repository",
      url: "https://github.com/SBalaSundar/Tuberculosis-Detection-Using-DeepLearning"
    }
  },
  {
    name: "Netflix UI Clone using React",
    desc: "A single page web application which have a same look ok Netflix's home page built using React.js and deployed on firebase.",
    link: {
      name: "Live Demo",
      url: "https://github.com/SBalaSundar/Tuberculosis-Detection-Using-DeepLearning"
    }
  },
  {
    name: "Chat Bot - Hotel Reservation",
    desc: "A simple AI chat bot to handle table reservation and to answer the FAQs. Built using RASA framework.",
    link: {
      name: "Visit Repository",
      url: "https://github.com/SBalaSundar/hotel_chat_bot.git"
    }
  }
]